<script>
  import { mapActions, mapGetters } from "vuex";
  import { Bar } from 'vue-chartjs'
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  export default {
    extends: Bar,
    data: () => ({
      chartdata: {
        labels: [],
        datasets: [
          {
            label: 'Job',
            backgroundColor: '#00266f',
            data: []
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }),
    computed: {
      ...mapGetters({
          jobsForReport: 'job/GETTER_JOB_FOR_REPORT',
          reportCriteria: 'job/GETTER_REPORT_CRITERIA'
      }),
    },
    methods: {
      ...mapActions({
        getJobsForReport: 'job/GET_JOB_FOR_REPORT',
        initReport: 'job/INITAL_REPORT'
      }),
      getDates(start, end) {
        var datesArray = [];
        var startDate = new Date(start);
        var endDate = new Date(end);
        while (startDate <= endDate) {
            let index = startDate.getMonth()+1;
            if(typeof datesArray[index]  !== 'undefined' && datesArray[index].length > 0){
              datesArray[index].push(new Date(startDate).getDate());
            }else{
              datesArray[index] = [new Date(startDate).getDate()];
            }
            startDate.setDate(startDate.getDate() + 1);
        }
        return datesArray;
      }
    },
    async created() {
      await this.initReport(); 
      await this.getJobsForReport();
    },
    mounted () {
      this.renderChart(this.chartdata, this.options)
    },
    watch: {
      jobsForReport(){
        let allLabels = [];
        let allDatas = [];
        let allDays = this.getDates(this.reportCriteria.start, this.reportCriteria.end);
        let self = this;

        allDays.map((item, key)=>{
          item.map((date)=>{
            allLabels.push(`${monthNames[key-1]} ${date}`);
          })
        });

        allLabels.map((item, key)=>{
          var count = self.jobsForReport.reduce(function(n, val) {
            return n + (val.jobDate === item);
          }, 0);
          allDatas.push(count);
        })
        
        this.chartdata.labels = allLabels
        this.chartdata.datasets[0].label = `Job (${self.jobsForReport.length})`
        this.chartdata.datasets[0].data = allDatas;
        this.$data._chart.update();
      }
    }
  }
</script>