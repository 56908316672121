<template>
  <div>
    <!-- slot for parent component to activate the file changer -->
    <div @click="launchFilePicker()">
      <v-row>
        <v-col cols="12" lg="4" class="text-center pa-0">
          <slot name="activator"></slot>
        </v-col>
        <v-col cols="12" lg="8">
          <slot name="description"></slot>
        </v-col>
      </v-row>
    </div>
    <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
    <input
      col-6
      type="file"
      ref="file"
      :name="`job_image_${detail.tmp_id}`"
      @change="onFileChange($event.target.name, $event.target.files)"
      style="display: none"
    />

    <input
      col-6
      type="text"
      :ref="`image_label_${detail.tmp_id}`"
      :name="`image_label_${detail.tmp_id}`"
      value=""
      style="display: none"
    />

    <input
      col-6
      type="text"
      :ref="`image_desc_${detail.tmp_id}`"
      :name="`image_desc_${detail.tmp_id}`"
      value=""
      style="display: none"
    />

    <v-dialog
      v-model="formDialog"
      max-width="600"
      :persistent="disableClickOutside"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Upload Image</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :disabled="detail.id <= 6 || fieldDisable"
                  :filled="detail.id <= 6"
                  :ref="`dialog_image_label_${detail.tmp_id}`"
                  :name="`dialog_image_label_${detail.tmp_id}`"
                  label="คำอธิบายใต้ภาพ"
                  v-model="detail.label"
                  :rules="[required('Title')]"
                  outlined
                  dense
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-textarea
                  :disabled="fieldDisable"
                  rows="3"
                  :ref="`dialog_image_desc_${detail.tmp_id}`"
                  :name="`dialog_image_desc_${detail.tmp_id}`"
                  label="รายละเอียด"
                  v-model="detail.description"
                  :rules="[required('Description')]"
                  outlined
                  dense
                  required
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <template v-if="!image">
                <v-btn
                  class="custom-image-upload py-12"
                  @click="launchPicker()"
                  text
                  block
                  x-large
                  >Upload Image</v-btn
                >
              </template>
              <v-img
                v-else
                :src="imageUrl"
                width="100%"
                class="grey lighten-2"
                @click="fieldDisable ? false : onRemoveImage(detail.mediaId)"
              >
                <v-expand-transition v-if="!fieldDisable">
                  <div
                    class="
                      d-flex
                      transition-fast-in-fast-out
                      error
                      darken-2
                      v-card--reveal
                      display-3
                      white--text
                    "
                  >
                    <v-icon color="#fff" v-text="'mdi-delete-outline'"></v-icon>
                  </div>
                </v-expand-transition>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text v-if="errorDialog" class="subheading red--text">{{
          errorText
        }}</v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="onDownloadImage()"
            >Download</v-btn
          >
          <v-btn
            v-if="detail.position == 'extra' && !fieldDisable"
            color="red"
            text
            @click="removeOptionTemp(detail.tmp_id)"
            >Remove</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="onCancel()">{{ cancelLabel }}</v-btn>
          <v-btn
            v-if="!fieldDisable"
            color="blue darken-1"
            text
            @click="onSaveImage()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import validations from "@/helpers/validations";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "ImageInput",
  data: () => ({
    ...validations,
    disableClickOutside: true,
    errorDialog: null,
    formDialog: null,
    errorText: "",
    maxSize: 1024,
    image: null,
    imageUrl: null,
    tempImageLabel: null,
    tempImageDesc: null,
    cancelLabel: null,
  }),
  props: {
    // Use "value" to enable using v-model
    detail: null,
    value: Object,
    fieldDisable: Boolean,
    docNo: null,
  },
  watch: {
    detail(value) {
      if ((!this.imageUrl || this.imageUrl != value.img.imageURL) && value.img && value.image) {
        this.image = value.image;
        this.imageUrl = value.img.imageURL;
      } else {
          this.image = null
          this.imageUrl = null
      }
    },
  },
  created() {
    this.image = this.detail.image;
    this.imageUrl = this.detail.img ? this.detail.img.imageURL : null;
  },
  methods: {
    ...mapActions({
      updateMediaItems: "job/UPDATE_MEDIA_ITEMS",
      removeOptionTemp: "job/REMOVE_OPTIONAL_MEDIA",
      removeImage: "job/REMOVE_IMAGE",
    }),
    launchFilePicker() {
      this.formDialog = true;
      this.tempImageLabel = this.detail.label;
      this.tempImageDesc = this.detail.description;
      this.cancelLabel = this.fieldDisable ? "Close" : "Cancel";
    },
    launchPicker() {
      this.$refs.file.click();
    },
    onRemoveImage(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5050",
        cancelButtonColor: "#C6C6C6",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          if (id) {
            this.removeImage({ id: this.$route.params.id, mediaId: id }).then(
              (isSuccess) => {
                if (isSuccess) {
                  this.image = "";
                  this.detail.img = {};
                  this.$refs.file.value = null;
                  Swal.fire(
                    "Deleted!",
                    "Your image has been deleted.",
                    "success"
                  );
                } else {
                  Swal.fire(
                    "Can not delete!",
                    "Something went wrong.",
                    "error"
                  );
                }
              }
            );
          } else {
            this.image = "";
            this.detail.img = {};
            this.$refs.file.value = null;

            Swal.fire("Deleted!", "Your image has been deleted.", "success");
          }
        }
      });
    },
    onCancel() {
      this.$refs[`image_label_${this.detail.tmp_id}`].value =
        this.tempImageLabel;
      this.detail.label = this.tempImageLabel;

      this.$refs[`image_desc_${this.detail.tmp_id}`].value = this.tempImageDesc;
      this.detail.description = this.tempImageDesc;
      this.formDialog = false;
    },
    onSaveImage() {
      this.$refs[`image_label_${this.detail.tmp_id}`].value = `${
        this.$refs[`dialog_image_label_${this.detail.tmp_id}`].value
      }`;
      this.$refs[`image_desc_${this.detail.tmp_id}`].value = `${
        this.$refs[`dialog_image_desc_${this.detail.tmp_id}`].value
      }`;

      const refFile = this.$refs.file.files;
      // this.detail.media = refFile.length > 0 ? refFile[0] : this.image;
      this.detail.media = this.image ? this.image : refFile.length > 0 ? refFile[0] : null
      this.updateMediaItems(this.detail);

      this.formDialog = false;
    },
    async onFileChange(fieldName, file) {
      const { maxSize } = this;

      let imageFile = file[0];
      if (file.length > 0) {
        let size = imageFile.size / maxSize / maxSize;
        if (!imageFile.type.match("image.*")) {
          // check whether the upload is an image
          this.errorDialog = true;
          this.errorText = "Please choose an image file";
        } else if (size > 20) {
          // check whether the size is greater than the size limit
          this.errorDialog = true;
          this.errorText =
            "Your file is too big! Please select an image under 1MB";
        } else {
          await this.optimizeImage(imageFile).then(async (res) =>{
            await this.convertURLtoFile(res, imageFile.name, imageFile.type).then((image)=>{
              // console.log(image)
              // console.log((imageFile.size - image.size) / imageFile.size *100)
              this.image = image;
            })
          })
          // Append file into FormData and turn file into image URL
          let formData = new FormData();
          let imageURL = URL.createObjectURL(this.image);
          this.imageUrl = imageURL;
          formData.append(fieldName, this.image);
          // Emit the FormData and image URL to the parent component
          this.$emit("input", {
            formData,
            imageURL,
          });
        }
      }
    },
    onDownloadImage() {
      const FileDownload = require("js-file-download");
      // console.log('onDownloadImage : ',this.detail.img.imageURL)
      // window.open(this.detail.img.imageURL);

      axios({
        url: this.detail.img.imageURL,
        method: "GET",
        responseType: "blob", // Important
      }).then((response) => {
        FileDownload(
          response.data,
          this.docNo + "_" + this.detail.label + ".jpg"
        );
      });
    },
    async optimizeImage (file) { // <= this must be asynchronous
      const reader = new FileReader();

      // Wait for the data url to be loaded from the file
      const dataURL = await new Promise(resolve => {
          reader.onload = (e) => resolve(e.target.result);
          reader.readAsDataURL(file);
      });

      // Wait for the image to be loaded
      const img = new Image();
      await new Promise(resolve => {
          img.onload = resolve;
          img.src = dataURL;
      });

      // Resize the image with a canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      
      // This is an addition to keep the aspect ratio of the image so it won't get distorted
      // const [maxWidth, maxHeight] = [260, 194];
      const [maxWidth, maxHeight] = [img.naturalWidth, img.naturalHeight];
      const [imgWidth, imgHeight] = [
          img.naturalWidth,
          img.naturalHeight
      ];
      const ratio = Math.min(maxWidth / imgWidth, maxHeight / imgHeight);

      canvas.width = imgWidth * ratio;
      canvas.height = imgHeight * ratio;

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      return canvas.toDataURL(file.type, 0.5) // `image/jpeg`
    },
    async convertURLtoFile (url, name, type) {
      const response = await fetch(url);
      const data = await response.blob();
      const filename = `${name}`
      const metadata = { type: type };
      return new File([data], filename, metadata);
    },
  },
};
</script>

<style scoped>
.custom-image-upload {
  background-color: #e1e1e1;
}
</style>
