<script>
import { mapActions, mapGetters } from "vuex";
import { Pie } from 'vue-chartjs'
export default {
    extends: Pie,
    data: () => ({
        status: ["open","reject","closed","requested","cancel","draft"],
        chartdata: {
            labels: ["open","reject","closed","requested","cancel","draft"],
            datasets: [
                {
                    backgroundColor: [
                        '#F44336',
                        '#B71C1C',
                        '#4CAF50',
                        '#ffa000',
                        '#bdbdbd',
                        '#555',
                    ],
                    data: []
                }
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false
        }
    }),
    computed: {
      ...mapGetters({
          jobsForReport: 'job/GETTER_JOB_FOR_REPORT',
          reportCriteria: 'job/GETTER_REPORT_CRITERIA'
      }),
    },
    methods: {
      ...mapActions({
        getJobsForReport: 'job/GET_JOB_FOR_REPORT',
      }),
    },
    async created() {
      await this.getJobsForReport();
    },
    mounted () {
        this.renderChart(this.chartdata, this.options)
    },
    watch: {
        jobsForReport(){
            let self = this;

            if(self.jobsForReport.length >= 1){
                let allDatas = [];
                this.status.map((item, key)=>{
                    var count = self.jobsForReport.reduce(function(n, val) {
                        return n + (val.status === item);
                    }, 0);
                    allDatas.push(count);
                })
                this.chartdata.labels = [ `open (${allDatas[0]})`
                                        , `reject (${allDatas[1]})`
                                        , `closed (${allDatas[2]})`
                                        , `requested (${allDatas[3]})`
                                        , `cancel (${allDatas[4]})`
                                        , `draft (${allDatas[5]})`],
                this.chartdata.datasets[0].backgroundColor = ['#F44336','#B71C1C','#4CAF50','#ffa000','#bdbdbd','#555']
                this.chartdata.datasets[0].data = allDatas;
                this.$data._chart.update();
            }else{
                this.chartdata.labels = [ `Not found (0)`
                                        , `reject (0)`
                                        , `closed (0)`
                                        , `requested (0)`
                                        , `cancel (0)`
                                        , `draft (0)`],
                this.chartdata.datasets[0].backgroundColor = ['#D3D3D3'];
                this.chartdata.datasets[0].data = [100];
                this.$data._chart.update();
            }
        }
    }
}
</script>

