<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
      absolute: true,
      overlay: true,
  }),
  created(){
      document.title = "Toyota Muangchon"
  }
};
</script>

<style lang="scss">
@import './assets/styles/global.scss';
</style>