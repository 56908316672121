import api from "@/services/api";
import router from "@/router/index";
import { LOGIN, LOGOUT } from "@/store/actions/account";
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_USER, SET_DIALOG_STATUS } from "@/store/mutations/account";

const state = {
    user: JSON.parse(localStorage.getItem("user")) || null,
    dialogStatus: false,
};

const actions = {
    [LOGIN]({ commit }, { identifier, password }) {
        api().post("/auth/local", {
            identifier: identifier,
            password: password
        })
        .then(response => {
            let user = response.data;
            commit(LOGIN_SUCCESS, user);
            window.location.href = "/";
        })
        .catch(() => {
            commit(LOGIN_FAILURE);
        });
    },
    [LOGOUT]({ commit }) {
        commit(LOGOUT_USER);
        router.push("/login");
    }
};

const mutations = {
    [LOGIN_SUCCESS](state, info) {
        // login successful if there's a jwt token in the response
        if (info.jwt && info.user) {
            // store user details and jwt token in local storage
            localStorage.setItem("jwt", JSON.stringify(info.jwt));
            localStorage.setItem("user", JSON.stringify(info.user));
        }
        state.user = info.user;
    },
    [LOGIN_FAILURE](state) {
        state.user = null;
    },
    [LOGOUT_USER](state) {
        // remove user from local storage
        localStorage.removeItem("jwt");
        localStorage.removeItem("user");
        state.user = null;
    },
    [SET_DIALOG_STATUS](state, status) { state.dialogStatus = status }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};