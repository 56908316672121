const routes = [
    {
        path: "/",
        meta: {
            middleware: [
                'AuthMiddleware'
            ]
        },
        component: () =>
            import(/* webpackChunkName: "dashbaord" */ "@/views/Dashboard.vue"),
        children: [
            {
                path: '',
                name: 'dashboard',
                redirect: { name: 'dashboard.job' }
            },
            {
                path: "job",
                name: "dashboard.job",
                meta: {
                    middleware: [
                        'AuthMiddleware'
                    ]
                },
                component: () =>
                  import(/* webpackChunkName: "home" */ "@/views/job/Index.vue")
            },
            {
                path: "job/create",
                name: "dashboard.jobCreate",
                meta: {
                  middleware: ['AuthMiddleware']
                },
                component: () =>
                  import(
                    /* webpackChunkName: "expenses" */ "@/views/job/Create.vue"
                  )
              },
              {
                path: "job/draft/:id",
                name: "dashboard.jobDraft",
                meta: {
                  middleware: ['AuthMiddleware']
                },
                component: () =>
                  import(
                    /* webpackChunkName: "expenses" */ "@/views/job/Draft.vue"
                  )
              },
              {
                path: "job/edit/:id",
                name: "dashboard.jobEdit",
                meta: {
                  middleware: ['AuthMiddleware']
                },
                component: () =>
                  import(
                    /* webpackChunkName: "expenses" */ "@/views/job/Edit.vue"
                  )
              },
              {
                path: "report",
                name: "dashboard.report",
                meta: {
                    middleware: [
                        'AuthMiddleware',
                        'PermissionMiddleware'
                    ]
                },
                component: () =>
                  import(/* webpackChunkName: "home" */ "@/views/report/Index.vue")
              },
            // {
            //     path: "/profile",
            //     name: "dashboard.profile",
            //     meta: {
            //         middleware: [
            //             AuthMiddleware
            //         ]
            //     },
            //     component: () =>
            //       import(/* webpackChunkName: "home" */ "@/views/profile/Index.vue")
            // }
        ]
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "login" */ "@/views/Login.vue")
    },
    {
    path: "/404",
    alias: "*",
    name: "not-found",
    component: () =>
        import(/* webpackChunkName: "not-found" */ "@/views/errors/PageNotFound.vue")
    },
    {
    path: "/permission-denied",
    name: "PermissionDenied",
    component: () =>
        import(/* webpackChunkName: "permission-denied" */ "@/views/errors/Permission.vue")
    }
];

export default routes;