import api from "@/services/api";
import axios from "axios"
import { GET_JOBS, GET_JOB_DETAIL } from "@/store/actions/job";
import router from "@/router/index";
import _ from "lodash"
import moment from 'moment';

const typeEnum = Object.freeze({
    json: 1,
    file: 2
});

const state = {
  jobs: [],
  responsibility: [],
  responsibilityForSearch: [],
  jobForm: {
    number: "",
    jobID: "",
    carRegistration: "",
    carRegistrationProvince: "กรุงเทพมหานคร",
    jobDate: "",
    jobResponsibility: "",
    orderType: "",
    description: "",
    status: "",
    detail: [],
    complaint: [],
    responsibilityName: "",
    created_by: "",
    updated_by: ""
  },
  jobMedia: [],
  jobComplaint: [],
  orderType: ['sameday','advance'],
  provinces: [],
  signature: "",
  jobsForReport: [],
  reportCriteria: {start:null, end:null, responsibility:null, branch:null},
  keepFilter: {
      date: { from: null ,to: null },
      carRegistration: null,
      jobResponsibility: null,
      branch: null,
      status: null
  },
  searchFilter: false
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const actions = {
  async [GET_JOBS]({ commit }) {
    let user = JSON.parse(localStorage.getItem('user'));
    let url;
    url = user.role.name == 'Admin' ? `/jobs-table?_limit=1000&_sort=created_at:DESC` : `/jobs-table?_limit=1000&_where[_or][0][jobResponsibility]=${user.id}&_where[_or][1][jobResponsibility.teamLeader]=${user.id}&_sort=created_at:DESC`;
    await api().get(url).then(res => {
        if(res.status == 200){
            commit('SETTER_JOBS', res.data)
        } else {
            console.log("alert error!")
        }
    })
  },
  CREATE_JOB({ commit, dispatch }, data) {
    api().post('/jobs', data).then((res)=>{
      const { data, status } = res
      commit('SETTER_JOBS', data)
      data.status === 'draft' ? router.push(`/job/draft/${data.id}`) : router.push(`/job/edit/${data.id}`)
      if(status === 200) {
        dispatch(
          `global/ADD_ALERT`,
          { message: `Your ${data.status} job id: ${data.id} has been created!`, color: "success" },
          { root: true }
        );
      }
      window.scrollTo(0,0);
    });
  },
  DELETE_JOB({ dispatch }, id) {
    api().delete(`/jobs/${id}`).then(()=>{
      dispatch('GET_JOBS')
    });
  },
  async GET_RESPONSIBILITY({ commit }) {
    let me = JSON.parse(localStorage.getItem('user'));
    const { role : { name : currentUserRole } } = me;
    const { data } = currentUserRole == "Admin" ? await api().get(`/users?_limit=-1&id_ne=${me.id}`) : await api().get(`/users/teams`)

    data.push(me);
    data.reverse();
    commit('SETTER_RESPONSIBILITY', data)
  },
  async GET_RESPONSIBILITY_SEARCH({ commit }) {
    let me = JSON.parse(localStorage.getItem('user'));
    const { role : { name : currentUserRole } } = me;
    const { data } = currentUserRole == "Admin" ? await api().get(`/users?_limit=-1&id_ne=${me.id}`) : await api().get(`/users/teams`)

    data.push(me);
    data.reverse();
    commit('SETTER_RESPONSIBILITY_SEARCH', data)
  },
  CLEAR_JOB_FORM({ commit }, { form, media }) {
    commit('SETTER_JOB_FORM', form)
    commit('SETTER_JOB_MEDIA', media)
  },
  UPDATE_MEDIA_ITEMS({ state, commit }, mediaItem) {
    let mediaAll = state.jobMedia;
    let foundIndex = mediaAll.findIndex(media => media.tmp_id === mediaItem.tmp_id)
    if(foundIndex >= 0) {
      mediaAll = mediaAll.map((media, index) => (index === foundIndex) ? mediaItem : media)
    } else {
      mediaAll.push(mediaItem)
    }
    commit('SETTER_JOB_MEDIA', mediaAll)
  },
  ADD_VIDEO({ state, dispatch }, fileVideo) {
    let mediaAll = state.jobMedia
    let videoIndex = mediaAll.findIndex(item => item.type === "video")
    if(videoIndex !== -1) {
      mediaAll[videoIndex].media = fileVideo
      dispatch('UPDATE_MEDIA_ITEMS', mediaAll)
    } else {
      let sorted = mediaAll.sort((a, b) => a.tmp_id - b.tmp_id);
      let lastItem = sorted[sorted.length - 1];
      let newId = parseInt(lastItem.tmp_id) + 1;
      let tmp = {
        // id: newId,
        tmp_id: newId,
        label: "Video",
        description: "-",
        img: {},
        media: fileVideo,
        position: 'extra',
        section: 'open',
        status: 'active',
        type: 'video'
      }
      dispatch('UPDATE_MEDIA_ITEMS', tmp)
    }
  },
  ADD_MAIN_IMG({ state, dispatch }, { index, fileImages, imageURL, edit=false }) {
    let mediaAll = state.jobMedia
    let foundIndex = mediaAll.findIndex(j => j.tmp_id === index)

    if(foundIndex >= 0){
      let section = edit ? 'close' : 'open'
      let formData = new FormData()
      formData.append(`job_image_${foundIndex}`, fileImages)
  
      mediaAll[foundIndex] = {
        ...mediaAll[foundIndex],
        img: {
          formData: formData,
          imageURL: imageURL
        },
        image: fileImages,
        media: fileImages,
        mediaId: null,
        section: section
      }

      dispatch('UPDATE_MEDIA_ITEMS', mediaAll[foundIndex])
    }
  },
  async ADD_MULTI_IMG({ state, dispatch }, { fileImages, imageURL, edit=false }) {
    let mediaAll = state.jobMedia
    let sorted = mediaAll.sort((a, b) => a.tmp_id - b.tmp_id);
    let lastItem = sorted[sorted.length - 1];
    let newId = lastItem ? parseInt(lastItem.tmp_id) + 1 : 1;
    let section = edit ? 'close' : 'open'
    let formData = new FormData()
    formData.append(`job_image_${newId}`, fileImages)
    let tmp = {
      tmp_id: newId,
      label: "เพิ่มเติม",
      description: "-",
      img: {
        formData: formData,
        imageURL: imageURL
      },
      image: fileImages,
      media: fileImages,
      position: 'extra',
      section: section,
      status: 'active',
      type: 'image'
    }

    dispatch('UPDATE_MEDIA_ITEMS', tmp)
  },

  ADD_SIGNATURE({ state, dispatch }, {fileSignature, base64}) {
    let mediaAll = state.jobMedia
    let signatureIndex = mediaAll.findIndex(item => item.type === "image" && item.position === "signature")
    if(signatureIndex !== -1) {
      mediaAll[signatureIndex].media = fileSignature
      dispatch('UPDATE_MEDIA_ITEMS', mediaAll)
    } else {
      let sorted = mediaAll.sort((a, b) => a.tmp_id - b.tmp_id);
      let lastItem = sorted[sorted.length - 1];
      let newId = parseInt(lastItem.tmp_id) + 1;
      let tmp = {
        // id: newId,
        tmp_id: newId,
        label: "Signature",
        description: "-",
        img: {},
        media: fileSignature,
        position: 'signature',
        section: 'close',
        status: 'active',
        type: 'image'
      }
      dispatch('UPDATE_MEDIA_ITEMS', tmp)
    }
    state.signature = base64;
  },
  ADD_OPTION_TMP({ state }, edit=false){
    let mediaAll = state.jobMedia
    let sorted = mediaAll.sort((a, b) => a.tmp_id - b.tmp_id);
    let lastItem = sorted[sorted.length - 1];
    let newId = parseInt(lastItem.tmp_id) + 1;
    let section = edit ? 'close' : 'open'
    let tmp = {
      // id: newId,
      tmp_id: newId,
      label: "เพิ่มเติม",
      description: "-",
      img: {},
      position: 'extra',
      section: section,
      status: 'active',
      type: 'image'
    }
    state.jobMedia.push(tmp);
  },
  REMOVE_OPTIONAL_MEDIA({ state }, id) {
    let mediaAll = state.jobMedia
    let index = mediaAll.findIndex(item => item.tmp_id == id)
    if (index !== -1) state.jobMedia.splice(index, 1);
  },
  REMOVE_VIDEO_OPEN_MEDIA({ state }) {
    let mediaAll = state.jobMedia
    let index = mediaAll.findIndex(item => item.type == "video" && item.section == "open")
    if (index !== -1){
      state.jobMedia.splice(index, 1);
    }
  },
  REMOVE_SIGNATURE({ state }) {
    let mediaAll = state.jobMedia
    let index = mediaAll.findIndex(item => item.type == "image" && item.position == "signature")
    if (index !== -1){
      state.jobMedia.splice(index, 1);
      state.signature = "";
    }
  },
  async [GET_JOB_DETAIL]({ commit }, { id, fromEdit=false }) {
    const { data } = await api().get(`/jobs/${id}`)
    if(fromEdit) {
      let foundClose = data.detail.findIndex(item => item.section == "close")
      if(foundClose < 0) {
        let mediaCloseSec = [
          {
            label: "ด้านหน้ารถ",
            description: "-",
            img: {},
            position: 'front',
            section: 'close',
            status: 'active',
            type: 'image'
          },
          {
            label: "ด้านหลังรถ",
            description: "-",
            img: {},
            position: 'back',
            section: 'close',
            status: 'active',
            type: 'image'
          },
          {
            label: "ด้านซ้ายรถ",
            description: "-",
            img: {},
            position: 'left',
            section: 'close',
            status: 'active',
            type: 'image'
          },
          {
            label: "ด้านขวารถ",
            description: "-",
            img: {},
            position: 'right',
            section: 'close',
            status: 'active',
            type: 'image'
          },
          {
            label: "เลขไมล์",
            description: "-",
            img: {},
            position: 'mileage',
            section: 'close',
            status: 'active',
            type: 'image'
          },
          {
            label: "ยางอะไหล่/อุปกรณ์เสริม",
            description: "-",
            img: {},
            position: 'spareTire',
            section: 'close',
            status: 'active',
            type: 'image'
          }
        ]
        let mediaAll = data.detail
        let sorted = mediaAll.sort((a, b) => a.id - b.id);
        let lastItem = sorted[sorted.length - 1].id + 1;

        mediaCloseSec.map((item) => {
          item.tmp_id = lastItem;
          data.detail.push(item);
          lastItem++;
        })
        // data.detail.push(...mediaCloseSec)
      }
    }
    let result = await Promise.all(data.detail.map(async item => {
        if(item.media) {
          let mediaUrl = item.media.url;
          let imageURL;
          let file;
          state.signature = "";
          const { data } = await api(typeEnum.file).get(mediaUrl) 
          
          let formData = new FormData()
          if(item.type == 'video'){
            file = new File([data], item.media.name, {type: "video/mp4"});
            imageURL = process.env.VUE_APP_BASE_URL+mediaUrl.substring(1);
          }else if(item.position == 'signature'){
            file = new File([data], item.media.name, {type: "image/png"});
            imageURL = URL.createObjectURL(file)
            state.signature = imageURL
          }else{
            file = new File([data], item.media.name, {type: "image/jpeg"});
            imageURL = URL.createObjectURL(file)
          }
          
          formData.append(`job_image_${item.media.id}`, file)

          item.mediaId = item.media.id;
          item.img = {formData, imageURL};
          item.image = file;
          item.media = file;
        } else {
          let formData = new FormData()
          let imageURL = null

          item.img = {formData, imageURL};
          item.image = null;
        }
        // item.tmp_id = item.id;
        if(item.id) item.tmp_id = item.id;
        return item;
    }))
    
    let complaint = await Promise.all(data.complaint.map(async item => {
      item.tmp_id = item.id;
      return item;
    }));
    
    result.complaint = complaint;
    
    commit('SETTER_JOB_FORM', data)
    commit('SETTER_JOB_MEDIA', result)
  },
  ADD_COMPLAINT_TMP({ state }){
    let jobComp = state.jobComplaint
    let sorted = jobComp.sort((a, b) => a.tmp_id - b.tmp_id);
    let lastItem = sorted[sorted.length - 1];
    let newId = (lastItem) ? lastItem.tmp_id + 1 : 1
    let tmp = {
      // id: newId,
      tmp_id: newId,
      topic: "SA",
      content: "-"
    }
    state.jobComplaint.push(tmp);
  },
  UPDATE_COMPLAINT({ state, commit }, item) {
    let complaintList = state.jobComplaint
    complaintList = complaintList.map(comp => {
      return (comp.tmp_id === item.tmp_id) ? item : comp
    })
    commit('SETTER_JOB_COMPLAINT', complaintList)
  },
  REMOVE_COMPLAINT({ state }, id) {
    let complaintList = state.jobComplaint
    let index = complaintList.findIndex(item => item.tmp_id == id)
    if (index !== -1) state.jobComplaint.splice(index, 1);
  },
  INITIAL_COMPLAINT({ commit }, complaints) {
    commit('SETTER_JOB_COMPLAINT', complaints)
  },
  async PUT_JOB({ dispatch }, { id, data }) {
    await api().put(`/jobs/${id}`, data).then(res =>{
      const { status, data : { status: jobStatus } } = res;
      dispatch('GET_JOB_DETAIL', { id: id })
      if(status === 200) {
        if(router.currentRoute.name === "dashboard.jobDraft" && jobStatus === "open") {
          router.push(`/job/edit/${id}`);
        }
        
        dispatch(
          `global/ADD_ALERT`,
          { message: `Your job id: ${id} has been updated!`, color: "success" },
          { root: true }
        );
      }
      window.scrollTo(0,0);
    });
  },
  async REMOVE_IMAGE({ dispatch }, { id, mediaId }) {
    try {
      let res = await api().delete(`/upload/files/${mediaId}`)
      if(res.status === 200) {
        dispatch('GET_JOB_DETAIL', { id: id })
        return true;
      }
    } catch (error) {
      return false;
    }
  },
  async GET_PROVINCE({ commit }) {
    let { data:provinces } = await axios.get('/db/provinces.json')
    commit('SETTER_PROVINCE', provinces)
  },
  async INITAL_REPORT({ commit }) {
    let user = JSON.parse(localStorage.getItem('user'));
    let date = new Date();

    var lastday = function(y,m){
      return  new Date(y, m+1, 0).getDate();
    }

    let start = `${date.getFullYear()}-${("0" + (date.getMonth()+1)).slice(-2)}-01T00:00:00.000Z`
    let end = `${date.getFullYear()}-${("0" + (date.getMonth()+1)).slice(-2)}-${lastday(date.getFullYear(),date.getMonth())}T23:59:59.000Z`

    let responsibility = user.id
    let initCriteria = {start:start, end:end, responsibility:responsibility, branch:'All'}
    commit('SETTER_PARAM_FOR_REPORT', initCriteria)
  },
  async UPDATE_REPORT({ commit, dispatch }, {start, end, responsibility, branch}) {
    responsibility = responsibility == 99999 ? state.responsibilityForSearch.filter(x => x.id != 99999 
    ).map((x) => { return x.id }) : responsibility;
    
    let startDate = moment(start, 'YYYY-MM-DD').format('YYYY-MM-DD');
    let endDate = moment(end, 'YYYY-MM-DD').format('YYYY-MM-DD');
    
    let val_1 = `${startDate}T00:00:00.000Z`
    let val_2 = `${endDate}T23:59:59.000Z`
    
    let initCriteria = {start:val_1, end:val_2, responsibility:responsibility, branch:branch}
    commit('SETTER_PARAM_FOR_REPORT', initCriteria)
    dispatch('GET_JOB_FOR_REPORT')
  },
  async GET_JOB_FOR_REPORT({ commit }) {
    let url;
    url = `/jobs?_limit=-1&jobDate_gte=${state.reportCriteria.start}&jobDate_lte=${state.reportCriteria.end}`
    if(typeof state.reportCriteria.responsibility === 'object' && state.reportCriteria.responsibility !== null){
      state.reportCriteria.responsibility.map((item)=>{
        url += `&jobResponsibility.id=${item}`;
      })
    }
    else if(state.reportCriteria.responsibility){
      url = `/jobs?_limit=-1&jobResponsibility.id_eq=${state.reportCriteria.responsibility}&jobDate_gte=${state.reportCriteria.start}&jobDate_lte=${state.reportCriteria.end}`
    }
    
    if(state.reportCriteria.branch != "All"){
      url += `&number_contains=${state.reportCriteria.branch}`;
    }

    const { data } = await api().get(url)
    let jobs = data.map((item)=>{
      item.jobDate = `${monthNames[new Date(item.jobDate).getMonth()]} ${new Date(item.jobDate).getDate()}`
      return item;
    })
    commit('SETTER_JOB_FOR_REPORT', jobs)
  },
  UPDATE_KEEP_FILTER({ commit }, filter) {
    commit('SETTER_KEEP_FILTER', filter)
  },
  UPDATE_SEARCH_FILTER({ commit }, status) {
    commit('SETTER_SEARCH_FILTER', status)
  },
  UPDATE_JOBS({ commit }, jobs) {
    commit('SETTER_JOBS', jobs)
  }
}

const mutations = {
  SETTER_JOBS(state, jobs) {
    if(jobs.length > 1) {
      jobs = jobs.map(job => {
        if(job.jobResponsibility.id) job.jobResponsibility = job.jobResponsibility.id;
        return job;
      })
    } 
    // else {
    //   const { jobResponsibility : { id } } = jobs;
    //   jobs.jobResponsibility = id;
    // }
    state.jobs = jobs
  },
  SETTER_RESPONSIBILITY(state, responsibility) {
    let responseResult = responsibility.map(res => {
      const { id, firstname, lastname } = res
      let responseName = `${firstname} ${lastname}`
      return { id: id.toString(), responseName: responseName }
    })
    state.responsibility = responseResult
  },
  SETTER_RESPONSIBILITY_SEARCH(state, responsibility) {
    let responseResult = responsibility.map(res => {
      const { id, firstname, lastname } = res
      let responseName = `${firstname} ${lastname}`
      return { id: id.toString(), responseName: responseName }
    })

    let all = { id: 99999, responseName: "All" }
    responseResult.push(all);
    responseResult.reverse();
    state.responsibilityForSearch = responseResult
  },
  SETTER_JOB_FORM(state, form) {
    state.jobForm = form
  },
  SETTER_JOB_MEDIA(state, media) {
    state.jobMedia = media
  },
  SETTER_JOB_COMPLAINT(state, complaint) {
    state.jobComplaint = complaint
  },
  SETTER_PROVINCE(state, provinces) {
    state.provinces = provinces
  },
  SETTER_PARAM_FOR_REPORT(state, params) {
    state.reportCriteria = params
  },
  SETTER_JOB_FOR_REPORT(state, jobsForReport) {
    state.jobsForReport = jobsForReport
  },
  SETTER_KEEP_FILTER(state, filter) {
    state.keepFilter = filter
  },
  SETTER_SEARCH_FILTER(state, status) {
    state.searchFilter = status
  }
}

const getters = {
  GETTER_JOBS: (state) => state.jobs,
  GETTER_CREATED_BY: (state) => state.jobCreatedBy = JSON.parse(localStorage.getItem('user')).id,
  GETTER_UPDATED_BY: (state) => state.jobCreatedBy = JSON.parse(localStorage.getItem('user')).id,
  GETTER_REPONSIBILITY: (state) => state.responsibility,
  GETTER_REPONSIBILITY_SEARCH: (state) => state.responsibilityForSearch,
  GETTER_ORDER_TYPE: (state) => state.orderType,
  GETTER_JOB_FORM: (state) => state.jobForm,
  GETTER_JOB_MEDIA: (state) => state.jobMedia,
  GETTER_MEDIA_IMG: (state) => state.jobMedia.filter(item => item.type === 'image'),
  GETTER_JOB_COMPLAINT: (state) => state.jobComplaint,
  GETTER_PROVINCE: (state) => state.provinces,
  GETTER_SIGNATURE: (state) => state.signature,
  GETTER_JOB_FOR_REPORT: (state) => state.jobsForReport,
  GETTER_REPORT_CRITERIA: (state) => state.reportCriteria,
  GETTER_KEEP_FILTER: (state) => state.keepFilter,
  GETTER_SEARCH_FILTER: (state) => state.searchFilter
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};