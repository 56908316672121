import { ADD_ALERT } from "@/store/actions/global";
import { 
  CREATE_ALERT,
  SET_BAR_IMAGE, 
  SET_DRAWER 
} from "@/store/mutations/global";

const state = {
  loading: false,
  alert: {},
  barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
  barImage: "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
  drawer: null,
  isMobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))? true : false
}

const actions = {
  [ADD_ALERT]({ commit }, alert) {
    let setAlert = {};
    setAlert.show = true;
    setAlert.color = alert.color;
    setAlert.message = alert.message;
    commit(CREATE_ALERT, setAlert);
  },
  TOGGLE_LOADING({ commit }, { loading }) {
    commit('UPDATE_LOADING', loading);
  },
  async OPTIMIZE_IMAGE({ state, commit }, { file }) {
    const reader = new FileReader();

      // Wait for the data url to be loaded from the file
      const dataURL = await new Promise(resolve => {
          reader.onload = (e) => resolve(e.target.result);
          reader.readAsDataURL(file);
      });

      // Wait for the image to be loaded
      const img = new Image();
      await new Promise(resolve => {
          img.onload = resolve;
          img.src = dataURL;
      });

      // Resize the image with a canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      
      // This is an addition to keep the aspect ratio of the image so it won't get distorted
      // const [maxWidth, maxHeight] = [260, 194];
      const [maxWidth, maxHeight] = [img.naturalWidth, img.naturalHeight];
      const [imgWidth, imgHeight] = [
          img.naturalWidth,
          img.naturalHeight
      ];
      const ratio = Math.min(maxWidth / imgWidth, maxHeight / imgHeight);

      canvas.width = imgWidth * ratio;
      canvas.height = imgHeight * ratio;

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      return canvas.toDataURL(file.type, 0.5) // `image/jpeg`
  },
  async CONVERT_URL_TO_FILE({ state, commit }, { url, name, type }) {
    const response = await fetch(url);
    const data = await response.blob();
    const filename = `${name}`
    const metadata = { type: type };
    return new File([data], filename, metadata);
  },
};

const mutations = {
  [CREATE_ALERT](state, alert) {
    state.alert = alert;
  },
  [SET_BAR_IMAGE](state, payload) {
    state.barImage = payload;
  },
  [SET_DRAWER](state, payload) {
    state.drawer = payload;
  },
  UPDATE_LOADING(state, loading) {
    state.loading = loading;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
