import axios from "axios";
import store from "@/store";
import router from "@/router/index";
import { ADD_ALERT } from "@/store/actions/global";

const typeEnum = Object.freeze({
    json: 1,
    file: 2
});

const setupInterceptor = (type) => {

    let config = getConfig(type);
    let Api = axios.create(config);
    
    Api.interceptors.request.use(request => {
        //add authorization header with jwt token to each request
        let jwtToken = JSON.parse(localStorage.getItem("jwt"));
        if (jwtToken) {
          request.headers[
            "Authorization"
          ] = `Bearer ${jwtToken}`;
        }
        updateLoaderTo(true);
        return request;
    });

    Api.interceptors.response.use(
        response => {
            updateLoaderTo(false);
            return response
        },
        error => {
            updateLoaderTo(false);
            errorHandler(error);
            return Promise.reject(error);
        }
    );

    return Api;
};

const getConfig = (type) => {

    let config = {};
    switch (type) {
        case typeEnum.json:
            config = {
                baseURL: process.env.VUE_APP_BASE_URL
            };
        break;
        case typeEnum.file:
            config = {
                baseURL: process.env.VUE_APP_BASE_URL,
                responseType: "blob"
            };
        break;
    }

    return config;
};

const updateLoaderTo = (loading) => {
    store.dispatch(`global/TOGGLE_LOADING`, { loading: loading }, { root: true });
}

const errorHandler = (error) => {

    let { status, data } = error.response;
    let { message } = data.message[0].messages[0];
    let { name: routeName } = router.history.current;

    if(status == 400 || status == 401) {
        localStorage.clear();
        if(routeName != "Login") {
            router.push({ name: "Login" });
        }
    }

    store.dispatch(
        `global/${ADD_ALERT}`,
        { message: `STATUS ${status}: ${message}`, color: "error" },
        { root: true }
    );
}

export default (type = typeEnum.json) => setupInterceptor(type); 