import { AbilityBuilder, Ability } from '@casl/ability'

export default function defineAbilityFor(user) {
  const { can, cannot, rules } = new AbilityBuilder();

  if (user === "SA Team Leader" || user === "SA") {

    can(['see'], 'dashboard.report');

    can(['sortSpecial'],'jobIndex');

    if(user === "SA Team Leader"){
      can('all', 'orderType');
    } else {
      cannot('all', 'orderType');
    }

    cannot('delete', 'job');

    cannot(['edit'], 'job', ['requested','cancel','closed']);
    can(['edit'], 'job', ['open','draft','reject']);
    can(['editSome'], 'job', ['open']);
    cannot('comment','job',['requested'])

    cannot(['close','cancel','reject'], 'job', ['requested']);
    can(['save'], 'job', ['open','reject']);
    can(['approve'], 'job', ['open','reject','requested']);

    can(['see'], 'complaint', ['open','reject','requested','closed']);
    cannot(['add','edit'], 'complaint', ['closed']);

  } else if (user === "Admin") {

    can(['see'], 'dashboard.report');

    cannot(['sortSpecial'],'jobIndex');

    can('all', 'orderType');

    can('delete', 'job');

    cannot(['edit'], 'job', ['requested','cancel','closed']);
    can(['edit'], 'job', ['open','draft','reject']);
    can(['editSome'], 'job', ['open']);
    can('comment','job',['requested'])

    can(['close','cancel','reject'], 'job', ['requested']);
    can(['save','approve'], 'job', ['open','reject']);
    can(['save'], 'job', ['closed']);

    can(['see'], 'complaint', ['open','reject','requested','closed']);
    can(['add','edit'], 'complaint', ['closed']);

  }

  return new Ability(rules);
}