const th = {
    language: {
      en: 'English',
      th: 'ภาษาไทย'
    },
    login: {
      mainTitle: 'โตโยต้าเมืองชล',
      subTitle: 'ระบบใช้งานภายในองค์กร',
      username: 'ชื่อผู้ใช้งาน',
      password: 'รหัสผ่าน',
      submitButton: 'เข้าสู่ระบบ'
    }
  }
  
  export default th