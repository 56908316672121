<script>
import { mapActions, mapGetters } from "vuex";
import { Pie } from 'vue-chartjs'
export default {
    extends: Pie,
    data: () => ({
        topics: [
            "ความสุภาพ มารยาท",
            "ความกระตือรือล้น",
            "ความครบถ้วน อธิบายหลังการซ่อม/สอบถาม",
            "แจ้งความคืบหน้าการซ่อมของรถลูกค้า",
            "การประสานงานพนักงานรับรถ ส่วนงานที่เกี่ยวข้อง",
            "รูปไม่ครบ / รูปไม่ชัด"
        ],
        mainTopics: ["ล้างรถ","SA","ช่าง","อะไหล่","อื่นๆ"],
        chartdata: {
            labels: [
                "ความสุภาพ มารยาท",
                "ความกระตือรือล้น",
                "ความครบถ้วน อธิบายหลังการซ่อม/สอบถาม",
                "แจ้งความคืบหน้าการซ่อมของรถลูกค้า",
                "การประสานงานพนักงานรับรถ ส่วนงานที่เกี่ยวข้อง",
                "รูปไม่ครบ / รูปไม่ชัด"
            ],
            datasets: [
                {
                    backgroundColor: [
                        '#929195',
                        '#665a72',
                        '#8b7355',
                        '#587a8f',
                        '#987d84'
                    ],
                    data: []
                }
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false
        }
    }),
    computed: {
      ...mapGetters({
          jobsForReport: 'job/GETTER_JOB_FOR_REPORT',
          reportCriteria: 'job/GETTER_REPORT_CRITERIA'
      }),
    },
    methods: {
      ...mapActions({
        getJobsForReport: 'job/GET_JOB_FOR_REPORT',
      }),
    },
    async created() {
      await this.getJobsForReport();
    },
    mounted () {
        this.renderChart(this.chartdata, this.options)
    },
    watch: {
        jobsForReport(){
            let self = this;
            let foundComplaint = false;
            self.jobsForReport.map((item)=>{
                if(item.complaint.length > 0){
                    if(self.mainTopics.indexOf(item.complaint[0].topic) == -1){
                        foundComplaint = true;
                    }
                }
            });

            if(foundComplaint){
                let allDatas = [];
                this.topics.map((item, key)=>{
                    var count = self.jobsForReport.reduce(function(n, val) {
                        return n + val.complaint.reduce(function(k, val2) {
                            return k + (val2.topic === item);
                        }, 0);
                    }, 0);
                    allDatas.push(count);
                })

                this.chartdata.labels = [ `ความสุภาพ มารยาท (${allDatas[0]})`
                                         , `ความกระตือรือล้น (${allDatas[1]})`
                                         , `ความครบถ้วน อธิบายหลังการซ่อม/สอบถาม (${allDatas[2]})`
                                         , `แจ้งความคืบหน้าการซ่อมของรถลูกค้า (${allDatas[3]})`
                                         , `การประสานงานพนักงานรับรถ ส่วนงานที่เกี่ยวข้อง (${allDatas[4]})`
                                         , `รูปไม่ครบ / รูปไม่ชัด (${allDatas[5]})`
                                        ],
                this.chartdata.datasets[0].backgroundColor = [
                                                                '#929195',
                                                                '#665a72',
                                                                '#8b7355',
                                                                '#587a8f',
                                                                '#987d84',
                                                                '#84987d'
                                                              ]
                this.chartdata.datasets[0].data = allDatas;
                this.$data._chart.update();
            }else{
                this.chartdata.labels = [ `Not found (0)`
                                         , `ความสุภาพ มารยาท (0)`
                                         , `ความกระตือรือล้น (0)`
                                         , `ความครบถ้วน อธิบายหลังการซ่อม/สอบถาม (0)`
                                         , `แจ้งความคืบหน้าการซ่อมของรถลูกค้า (0)`
                                         , `การประสานงานพนักงานรับรถ ส่วนงานที่เกี่ยวข้อง (0)`
                                         , `รูปไม่ครบ / รูปไม่ชัด (0)`
                                        ];
                this.chartdata.datasets[0].backgroundColor = ['#D3D3D3'];
                this.chartdata.datasets[0].data = [100];
                this.$data._chart.update();
            }
        }
    }
}
</script>

