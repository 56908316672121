import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n';
import messages from './lang/translation';
import router from "./router";
import store from "./store";
import "./plugins/base";
import moment from 'moment';
import VueSignaturePad from 'vue-signature-pad';
import { abilitiesPlugin } from '@casl/vue';
import defineAbilitiesFor from './services/ability';

Vue.config.productionTip = false
Vue.use(VueI18n);
Vue.use(VueSignaturePad);

let loggedIn = localStorage.getItem("user");
let userRole = "guest";
if(loggedIn) {
  let activeUser = JSON.parse(loggedIn);
  const { role : { name : currentUserRole } } = activeUser;
  userRole = currentUserRole;
}
let ability = defineAbilitiesFor(userRole);
Vue.use(abilitiesPlugin, ability);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
});

const i18n = new VueI18n({
  locale: 'en', // global variable holding the local value
  messages, // globale variable holding translations
});

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
