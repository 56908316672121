const en = {
    language: {
      en: 'English',
      th: 'Thai'
    },
    login: {
      mainTitle: 'TOYOTA MUANGCHON',
      subTitle: 'INTERNAL ORGANIZATION SYSTEM',
      username: 'Username',
      password: 'Password',
      submitButton: 'Login'
    },
    sideMenus: {
      avatar: 'avatar',
      jobs: 'Jobs',
      reports: 'Reports',
      profile: 'Profile'
    }
  }
  
  export default en