const AuthMiddleware = (to, from, next) => {
    const loggedIn = localStorage.getItem("user");
    let auth = loggedIn ? true : false;
    
    if (!auth) {
      next({ name: "Login" });
    } else {
      next();
    }
};
  
export default AuthMiddleware;