<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :id="name"
        :name="name"
        :value="setvalue"
        :label="label"
        :placeholder="placeHolder"
        outlined
        required
        dense
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :disabled="disabled"
      :value="setvalue"
      @input="(menu = false), setDate($event), executeCallback()"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    name: String,
    label: String,
    setvalue: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    callback: Function,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menu: false,
    placeHolder: new Date().toISOString().substr(0, 10)
  }),
  methods: {
    setDate(newVal) {
      this.$emit("update:setvalue", newVal);
    },
    executeCallback() {
      if (this.callback) {
        this.callback();
      }
    }
  }
};
</script>
