<script>
import { mapActions, mapGetters } from "vuex";
import { Pie } from 'vue-chartjs'
export default {
    extends: Pie,
    data: () => ({
        topics: ["ล้างรถ","SA","ช่าง","อะไหล่","อื่นๆ"],
        otherSA: [
            "ความสุภาพ มารยาท",
            "ความกระตือรือล้น",
            "ความครบถ้วน อธิบายหลังการซ่อม/สอบถาม",
            "แจ้งความคืบหน้าการซ่อมของรถลูกค้า",
            "การประสานงานพนักงานรับรถ ส่วนงานที่เกี่ยวข้อง",
            "รูปไม่ครบ / รูปไม่ชัด"
        ],
        chartdata: {
            labels: ["ล้างรถ","SA","ช่าง","อะไหล่","อื่นๆ"],
            datasets: [
                {
                    backgroundColor: [
                        '#929195',
                        '#665a72',
                        '#8b7355',
                        '#587a8f',
                        '#987d84'
                    ],
                    data: []
                }
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false
        }
    }),
    computed: {
      ...mapGetters({
          jobsForReport: 'job/GETTER_JOB_FOR_REPORT',
          reportCriteria: 'job/GETTER_REPORT_CRITERIA'
      }),
    },
    methods: {
      ...mapActions({
        getJobsForReport: 'job/GET_JOB_FOR_REPORT',
      }),
    },
    async created() {
      await this.getJobsForReport();
    },
    mounted () {
        this.renderChart(this.chartdata, this.options)
    },
    watch: {
        jobsForReport(){
            let self = this;
            let foundComplaint = false;
            self.jobsForReport.map((item)=>{
              if(item.complaint.length > 0){
                foundComplaint = true;
              }
            });

            if(foundComplaint){
                let allDatas = [];
                this.topics.map((item, key)=>{
                    var count = self.jobsForReport.reduce(function(n, val) {
                        return n + val.complaint.reduce(function(k, val2) {
                            return k + (val2.topic === item || (item == 'SA' & (self.otherSA.indexOf(val2.topic) > -1)));
                        }, 0);
                    }, 0);
                    allDatas.push(count);
                })


                this.chartdata.labels = [ `ล้างรถ (${allDatas[0]})`
                                         , `SA (${allDatas[1]})`
                                         , `ช่าง (${allDatas[2]})`
                                         , `อะไหล่ (${allDatas[3]})`
                                         , `อื่นๆ (${allDatas[4]})`
                                        ],
                this.chartdata.datasets[0].backgroundColor = [
                                                                '#929195',
                                                                '#665a72',
                                                                '#8b7355',
                                                                '#587a8f',
                                                                '#987d84'
                                                              ]
                this.chartdata.datasets[0].data = allDatas;
                this.$data._chart.update();
            }else{
                this.chartdata.labels = [ `Not found (0)`
                                         , `SA (0)`
                                         , `ช่าง (0)`
                                         , `อะไหล่ (0)`
                                         , `อื่นๆ (0)`
                                        ];
                this.chartdata.datasets[0].backgroundColor = ['#D3D3D3'];
                this.chartdata.datasets[0].data = [100];
                this.$data._chart.update();
            }
        }
    }
}
</script>

