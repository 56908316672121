import defineAbilitiesFor from '@/services/ability';

const PermissionMiddleware = (to, from, next) => {
    const loggedIn = localStorage.getItem("user");
    let userRole = "guest";
    if(loggedIn) {
        let activeUser = JSON.parse(loggedIn);
        const { role : { name : currentUserRole } } = activeUser;
        userRole = currentUserRole;
    }

    let ability = defineAbilitiesFor(userRole);
    let routeName = to.name;
    if (ability.can('see', routeName)) {
      next();
    } else {
      next({ name: "PermissionDenied" });
    }
};
  
export default PermissionMiddleware;